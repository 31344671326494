/* Gloabl css */



*{
  padding: 0;
  margin: 0;
}


body{
  background-color: #181A20;
}


.clearx{
  clear: both;
}


.navbarx {
  overflow: hidden;
  background-color: #333;
  position: fixed;
  bottom: 0;
  width: 100%;
  display: none;
}




.floatingCryptocoins{
  width:100%;
  height:50px;
  /* background-color: red; */
  /* color:white; */
  margin-top: 100px;
}



.navBarContainerx{
  width:50px;
  height: 50px;
  /* background-color: red; */
  float: right;
  color:white;

  display: none;
}




.mainquickLinkStyle a{
  text-decoration: none;
}

.quickLinkStyle {
  height: 50px;
  width: 100%;
  /* background-color: green; */
  text-align: center;
  line-height: 50px;
  margin-bottom: 20px;

  background-color: #181a20;
  box-shadow: inset 0 0px 30px #d7760047;

}



.modal-dialog {
  position: fixed;
  bottom: 0;
  left: 0%;
  right: 0%;
  transform: translate(-50%, -50%);

 
}  

.modal-contentHomeNav{
  background-color: #181a20;
  box-shadow: inset 0 0px 30px #d7760047;
}




.modal-contentHomeNav h1{
  color:#eaecef;
}


.navContent{
  width:95%;
  height: auto;
  /* background-color: red; */

  margin: auto;
}


.navContentSub{
  width:100%;
}

.navContentSubContent{
  width:25%;
  height: 70px;
  background-color: #1E2026;

  float: left;
  margin-right:20px;
  margin-bottom:15px;

  position: relative;
  left:20px;
  border-radius:5px;
  box-shadow:  0px 0px 1px #F0B90B;
  border:none;
}

.navContentSubContent:hover{
  /* box-shadow: inset  0px 0px 1px #F0B90B; */
  box-shadow: inset 0 0px 30px #d7760047;
}

.navContentSubContent a{
  text-decoration: none;
  color: #eaecef;
  font-size:14px;
}


.navReg{
  width: 95%;
  height: 40px;
  background-color: #FCD535;
  text-align: center;
  line-height: 40px;
  border-radius:5px;
}

.navReg:hover{
  background-color: #F0B90B;
}


.navReg p{
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  color: black;
}


.navContent a{
  text-decoration: none;
}










/* other css */


/* header css */
.headerContainer{
  width:100%;
  height:80px;
  background-color: #181a20;
  box-shadow: 0px 2px 3px #F0B90B;

  position: fixed;
  top:0px;
  z-index: 50000000;

}

.headerContainer_Sub{
  width:100%;
  height:70px;
  /* background-color: red; */
  margin-top:10px;
}

.logoSection{
  width: 15%;
  height: 40px;
  /* background-color: red; */
  margin-top: 20px;

  float: left;
  margin-left: 15px;
}

.logoSection a{
  text-decoration: none;;
}

.navSection{
  width: 70%;
  height: 40px;
  /* background-color: pink; */
  margin-top: 20px;

  float: right;
  margin-right:15px;
}

/* logo */
.logoSection h2{
  color: #F0B90B;
  font-size:15px;
  text-align: center;
  line-height: 35px;

  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;

  
}


/* nav items */
.navItems{
  width: 100%;
}

.navItems ul{
  list-style-type: none;
}

.navItems ul li{
  display: inline;
  /* background-color: red; */
  margin-right:15px;
  line-height: 35px;
  padding:3px;

  color: #eaecef;
  font-size:14px;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;

}

.navItems ul li a{
  text-decoration: none;
  color: #eaecef;
  font-size:14px;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;

}

.navItems ul li a:hover{
  color: #FCD535;
  cursor: pointer;
  font-size:16px;
}

.googleTrans{
  background-color: #FCD535;
  padding: 3px;
  color:black;
  font-size:14px;
  border-radius: 5px;

}





/* showCaseContainer */
.showCaseContainer{
  width:100%;
  height: 470px;
  /* background-color: red; */
  margin-top: 30px;

  background:linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("./images/bg/bg1.webp");
   background-repeat:no-repeat;
   background-size:cover;
   background-position:center;
}

.showCaseContainer_Sub{
  width:100%;
}

.showCaseContainer_Sub_1{
 width: 100%;
}

.showCaseContainer_Sub_1_Container{
  width:40%;
  height: 450px;
  /* background-color: red; */
  margin-left:50px;
  padding-top: 150px;
}

.showCaseContainer_Sub_1_Container h3{
  font-size:45px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-weight: bold;
  color:#F0B90B;

  text-shadow: 1px 1px 1px #eaecef;
}

.showCaseContainer_Sub_1_Container p{
  color: #eaecef;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-size:15px;
}

.showCaseButtonContainer{
  width:100%;
  height: 100px;
  /* background:white; */
}

/* showCaseButtonContainer */
.showCaseButton_1{
  width: 30%;
  height: 40px;
  background-color: #FCD535;
  float: left;
  text-align: center;
  line-height: 35px;
  border-radius: 8px;
}

.showCaseButton_1 a{
  text-decoration: none;
  color:black;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  
}

.showCaseButton_2{
  width: 30%;
  height: 40px;
  background-color: #FCD535;
  float: right;
  margin-right:150px;

  text-align: center;
  line-height: 35px;
  border-radius: 8px;
}

.showCaseButton_1:hover{
  background-color: #F0B90B;
}

.showCaseButton_2:hover{
  background-color: #F0B90B;
}

.showCaseButton_2 a{
  text-decoration: none;
  color:black;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  
}

.showCaseParaBelow{
  padding-top:10px;
  color:#F0B90B;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;

}



/* about section */
.aboutSection{
  width: 100%;
  height:720px;
  /* background-color: red; */
}

.aboutSection_sub1{
  width:100%;
  height:50px;
  /* background-color: red; */
}

.aboutSection_sub2{
  width: 100%;
  height: 400px;
  /* background-color: red; */
}

.aboutSection_sub2_1{
  width:30%;
  height:400px;
  /* background-color: pink; */

  float: left;
  margin-left:50px;
}

.aboutSection_sub2_2{
  width:55%;
  height:640px;
  /* background-color: blue; */

  float: right;
  margin-right:50px;
}

.aboutSection_sub2_1_img{
  width:90%;
  height:300px;
  background-color: brown;
  margin:auto;
  margin-top:40px;
  border-radius: 8px;
  box-shadow: 2px 2px 5px #F0B90B;

  background: url("./images/bg/btc1.jpeg");
  background-repeat:no-repeat;
  background-size:cover;
  background-position:center;
}

.aboutSection_sub2_2 h2{
  padding-top:25px;
  color:#F0B90B;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;

}

.aboutSection_sub2_2 p{
  color:#eaecef;
  font-size:14px;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;

}

.aboutSection_sub2_2 h5{
  /* padding-top:25px; */
  font-size:17px;
  color:#F0B90B;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;

}

.companyCert{
  width: 30%;
  height: 40px;
  background-color: #FCD535;
  text-align: center;
  line-height: 35px;
  border-radius: 8px;
  cursor: pointer;
}

.companyCert:hover{
  background-color: #F0B90B;
}

.companyCert p{
  color:black;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;

}





/* whyChooseUsSection */
.whyChooseUsSection{
  width: 100%;
  height: 750px;
  background-color: #1E2026;
 
}

.whyChooseUsSectionContainer{
  width:100%;
  height: 500px;
  /* background-color: pink; */
}

.whyChooseUsSectionContainerHeading{
  width: 50%;
  height: 140px;
  /* background-color: red; */
  margin: auto;
 
}

.whyChooseUsSectionContainerHeading h2{
  text-align: center;
  color:#F0B90B;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  padding-top:50px;
}

.whyChooseUsSectionContainerHeading p{
  text-align: center;
  color:#eaecef;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-size:14px;
}

.whyChooseUsSectionContainerDetails{
  width:100%;
  /* height: 400px; */
  margin-top:35px;
  /* background-color: red; */
}

.whyChooseUsSectionContainerDetailsCard{
  width:30%;
  height: 250px;
  background-color: #181A20;
  float: left;
  margin-right: 20px;
  margin-bottom: 25px;
  position: relative;
  left: 50px;
  border-radius:8px;

  box-shadow: inset 0 0px 66px #d7760047;
  border: 1px solid #d77600;
}

.whyChooseUsSectionContainerDetailsCard_Details{
  width:100%;
  height: 200px;
  /* background-color: red; */
  margin-top:30px;
}

.whyChooseUsSectionContainerDetailsCard_Details h2{
  color:#F0B90B;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-size:24px;
  padding:15px;
}

.whyChooseUsSectionContainerDetailsCard_Details p{
  color:#eaecef;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  padding:15px;
  font-size:15px;
}







/* sectionFourContainer */

.sectionFourContainer{
  width:100%;
  height: 500px;
}

.sectionFourContainerSub{
  width:100%;
  height: 450px;
  /* background-color: red; */
  margin-top:30px;
}

.sectionFourContainerSubCard1{
  width:30%;
  height: 400px;
  /* background-color: blue; */
  background: url("./images/bg/mobile.svg");
  background-repeat:no-repeat;
  background-size:contain;
  background-position:center;


  float:left ;
  margin-left:100px;
  
}

.sectionFourContainerSubCard2{
  width:50%;
  height: 400px;
  /* background-color: blue; */

  float:right ;
  margin-right:50px;
}

.sectionFourContainerSubCard2Details{
  width:100%;
}

.sectionFourContainerSubCard2Details h3{
  color:#F0B90B;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;

}

.stepsContainer{
  width:100%;
  height:300px;
  margin-top:50px;
  /* background-color: red; */
}

.stepsContainer h5{
  color:#F0B90B;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;

}

.stepsContainer p{
  color:#eaecef;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  padding-bottom: 20px;
}





/* faqContainer */
.faqContainer{
  width:100%;
  height: 600px;
  /* background-color: red; */
}

.faqContainerSub{
  width: 70%;
  height: 450px;
  /* background-color: red; */
  margin:auto;
}

.faqContainerSub h2{
  text-align: center;
  color: #F0B90B;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;

}

.faqContainerSub p{
  color:#eaecef;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-size:13px;
  text-align: center;
}

.faqContainerSubForm{
  width:100%;
  /* height:300px; */
  margin-top:50px;
  /* background-color: red; */
}



/* referralContainer */

/* referralContainer */
.referralContainer{
  width:100%;
  height:300px;
  /* background-color: red; */
}

.referralContainerSub{
  width:50%;
  height: 200px;
  background-color: #1E2026;
  margin:auto;
  margin-top:30px;

  padding: 20px;
  border-radius: 8px;
  box-shadow: inset 0 0px 66px #d7760047;
  border: 1px solid #d77600;
}

.referralContainerSub h2{
  color:#F0B90B;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  text-align: center;
}

.referralContainerSub p{
  color:#eaecef;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  text-align: center;
  padding-bottom: 20px;
}

.referralContainerSub a{
  text-decoration: none;
  background-color: #FCD535;
  padding:8px;
  color:black;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  border-radius:6px;
}

.referralContainerSub a:hover{
  background-color: #F0B90B;
  
}



/* cryptoDebitCardContainer */
.cryptoDebitCardContainer{
  width: 100%;
  height: 400px;
  /* background-color: red; */
}

.cryptoDebitCardContainerSub{
  width:100%;
}

.cryptoDebitCardContainerSubCard1{
  width:30%;
  height: 300px;
  /* background-color: blue; */

  background: url("./images/bg/card2.svg");
  background-repeat:no-repeat;
  background-size:contain;
  background-position:center;

  float: left;
  margin-left:100px;
}

.cryptoDebitCardContainerSubCard2{
  width:50%;
  height: 200px;
  /* background-color: red; */
  

  margin-top: 50px;
  float: right;
  margin-right:70px;
}

.cryptoDebitCardContainerSubCard2 h2{
  color:#F0B90B;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;

}

.cryptoDebitCardContainerSubCard2 p{
  color:#eaecef;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;

}

.cryptoDebitCardContainerSubCard2 a{
  text-decoration: none;
  background-color: #FCD535;
  padding:8px;
  color:black;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  border-radius:6px;
}

.cryptoDebitCardContainerSubCard2 a:hover{
  background-color: #F0B90B;
}


/* testimonailContainer */
.testimonailContainer{
  width:100%;
  height: 650px;
  background-color: #1E2026;
}

.testimonailContainerSub{
  width:100%;
}

.testimonailContainerSub h2{
  color:#F0B90B;
  text-align: center;
  padding-top: 30px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;

}

.testimonailContainerSub p{
  color:#eaecef;
  text-align: center;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;

}

.testimonailCardContainer{
  width:100%;

}

.testimonailCard{
  width: 30%;
  height:200px;
  /* background-color: red; */
  border-radius: 8px;
  box-shadow: inset 0 0px 66px #d7760047;
  border: 1px solid #d77600;


  float: left;
  margin-right: 25px;
  margin-bottom: 15px;
  position: relative;
  left:39px;
  top:20px;
}

.testimonailCardDetails{
  width: 100%;
  height:170px;
  /* background-color: red; */
  margin-top:10px;
}

.testimonailCardDetails1{
  color:#eaecef;
  padding:9px;
  font-size:12px;
  text-align: center;
}


.testimonailCardDetails2{
  color:#eaecef;
  padding:9px;
  font-size:12px;
  text-align: center;
}

.testImage{
  width:50px;
  height: 50px;
  /* background-color: red; */
  border-radius: 50%;
}






/* footerContainer */
.footerContainer{
  width:100%;
  height: 260px;
  box-shadow: inset 0 0px 30px #d7760047;

}

.footerContainerSub{
  width:100%;
}

.footerContainerSubCard{
    width: 30%;
    height: 190px;
    /* background-color: red; */

    float: left;
    margin-right:30px;
    position: relative;
    left:35px;
    top:25px;
}

.footerContainerSubCard h3{
  color:#F0B90B;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-size:20px;
  padding:8px;
}

.footerContainerSubCard p{
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  color:#eaecef;
  font-size:14px;
  padding:8px;
}

.footerContainerSubCard ul{
  list-style-type: none;
}

.footerContainerSubCard h4{
  color:#F0B90B;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-size:20px;
  padding:8px;
  padding-left: 35px;
}

.footerContainerSubCard ul li a{
  color:#eaecef;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-size:14px;
  padding:5px;
  text-decoration: none;
}

.footLastText{
  color:#F0B90B;
  text-align: center;
  padding:10px;
}







/* about PAGE */

.aboutSection_sub2_1_img1{
  width:90%;
  height:300px;
  /* background-color: brown; */
  margin:auto;
  margin-top:40px;
  border-radius: 8px;
  /* box-shadow: 2px 2px 5px #F0B90B; */

  background: url("./images/bg/about1.svg");
  background-repeat:no-repeat;
  background-size:cover;
  background-position:center;
}



.aboutSection1{
  width:100%;
  height: 200px;
  /* background:red;   */
}

.aboutSection1Sub{
  width:100%;
}

.aboutSection1SubCard{
  width:25%;
  height:150px;
  background-color: #1E2026;
 
  float: left;
  margin-right:40px;

  position: relative;
  top:12px;
  left:130px;

  border-radius:8px;
  box-shadow: inset 0 0px 20px #d7760047;
 
}

.aboutSection1SubCardImg{
  width:100%;
  
}

.aboutSection1SubCardImg1{
  width:35%;
  height:150px;
  /* background-color: red; */

  float: left;
  margin-left:5px;

  background: url("./images/ab/supportimg.svg");
  background-repeat:no-repeat;
  background-size:contain;
  background-position:center;
}

.aboutSection1SubCardImg11{
  width:35%;
  height:150px;
  /* background-color: red; */

  float: left;
  margin-left:5px;

  background: url("./images/ab/withimg.svg");
  background-repeat:no-repeat;
  background-size:contain;
  background-position:center;
}

.aboutSection1SubCardImg111{
  width:35%;
  height:150px;
  /* background-color: red; */

  float: left;
  margin-left:5px;

  background: url("./images/ab/secure.svg");
  background-repeat:no-repeat;
  background-size:contain;
  background-position:center;
}






.aboutSection1SubCardImg2{
  width:60%;
  height:150px;
  /* background-color: red; */

  float: right;
  margin-right:5px;
}

.aboutSection1SubCardImg2 h2{
  color:#F0B90B;
  font-size:18px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  padding:10px;
}

.aboutSection1SubCardImg2 p{
  color:#eaecef;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-size:13px;
  /* text-wrap: balance; */
}




.ourTeamContainer{
  width:100%;
  height: 300px;
  /* background-color: red; */
  margin-top:50px;
}

.ourTeamContainerSub{
  width:90%;
  height: 250px;
  /* background-color: blue; */
  margin: auto;
  margin-top:10px;
}

.ourTeamContainerSub h2{
  text-align: center;
  padding:10px;
  color:#F0B90B;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;

}

.ourTeamContainerSubCard{
  width: 20%;
  height: 160px;
  background-color: #1E2026;

  float: left;
  margin-right:30px;

  position: relative;
  left:75px;
}

.founderImg{
  width:80px;
  height: 80px;
  /* background-color: red; */
  margin-top: 10px;
  border-radius: 50%;
}

.ourTeamContainerSubCard p{
  color:#eaecef;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  padding: 7px;
  font-size: 13px;
}



/* investment */
.plansContainer{
  width:100%;
  height: auto;
  /* background-color: red; */
}

.plansContainerSub{
  width:100%;
  margin-top:50px;
}

.plansContainerSub h2{
  color: #F0B90B;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;

}

.plansContainerSub p{
  color:#eaecef;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  max-width: 500px;
  padding: 10px;
  font-size:14px; 
}

.plansContainerSubCard{
  width:20%;
  height: 380px;
  background-color: #1E2026;

  float: left;
  margin-right:30px;
  margin-bottom:50px;
  /* margin-left:40px; */
  position: relative;
  left:90px;
  top:20px;

  border-radius:8px;
  box-shadow: inset 0 0px 20px #d7760047;
}


.plansContainerSubCard:hover{
  box-shadow:0px 0px 7px #eaecef;
}


.plansContainerSubCardContent{
  width:100%;
}

.planName{
  color:#F0B90B;
  padding:12px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;

}

.planName1{
  color:#FCD535;
  font-size: 25px;
}

.planName2{
  line-height: 35px;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;

}

.plansContainerSubCardContent a{
  text-decoration: none;
  background-color: #FCD535;
  padding: 10px;
  border-radius: 8px;
  color:black;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;

  
}

.plansContainerSubCardContent a:hover{
  background-color: #F0B90B;
  
}

.planName3{
  font-size:13px;
  color: #F0B90B;
  cursor: pointer;
}



/* contactContainer */
.contactContainer{
  width:100%;
  height: auto;
  margin-bottom: 50px;
}

.contactContainerContent{
  width:100%;
}

.contactContainerContent1{
  width:80%;
  height: auto;
  margin: auto;
  margin-top:50px;
  /* background-color: pink; */

 
}

.contactContainerContent1 h2{
  text-align: center;
  color:#F0B90B;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;

}

.contactForm{
  width:100%;
  height: 400px;
  /* background-color: blue; */
}



.contactForm input {
  background-color : #1E2026; 
  border: none;
  padding:10px;
  color:#eaecef;
}

.contactForm input:focus {
  background-color : #1E2026; 
  outline: none;
  border: none;
  box-shadow:  1px 1px 5px #F0B90B;
  color:#eaecef;
}

.contactForm input::placeholder  {
  color : #eaecef;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-size:14px;
  
}


.contactForm textarea {
  background-color : #1E2026; 
  border: none;
  resize: none;
  padding:10px;
  color:#eaecef;
}


.contactForm textarea:focus {
  background-color : #1E2026; 
  outline: none;
  border: none;
  box-shadow:  1px 1px 5px #F0B90B;
  color:#eaecef;
}

.contactForm textarea::placeholder {
  color : #eaecef;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-size:14px;
}


.buttForm{
  border:none;
  padding:8px;
  border-radius: 5px;
  background-color: #FCD535;
  color:black;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;

}

.buttForm:hover{
  background-color: #F0B90B;
}

.otherContactDetails{
  width: 100%;
  height: 200px;
  /* background-color: blue; */
}

.otherContactDetailsCard{
  width:30%;
  height: 200px;
  background-color: #1E2026;

	border-radius:8px;
  box-shadow: inset 0 0px 26px #d7760047;

  float: left;
  margin-right:30px;
  position: relative;
  left: 25px;
}

.otherContactDetailsCard:hover{
  box-shadow:0px 0px 7px #eaecef;
}

.contImage1{
  width:80px;
  height: 80px;
  /* background-color: red; */
  border-radius: 50%;
  margin-top:20px;
}

.otherContactDetailsCard h3{
  font-size: 15px;
  color: #F0B90B;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  padding: 8px;
}

.otherContactDetailsCard p{
  color:#eaecef;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-size: 12px;
}




/* login */

.userLoginContainer{
  width:100%;
  height: 450px;
  /* background-color: red; */
}

.userLoginContainerSub{
  width:100%;

}

.userLoginContainerSubCard1{
  width:30%;
  height:400px;
  /* background-color: red; */
  background: url("./images/bg/login.svg");
  background-repeat:no-repeat;
  background-size:contain;
  background-position:center;

  float: left;
  position:relative;
  top: 20px;
  left: 80px;
}

.userLoginContainerSubCard2{
  width:50%;
  height:400px;
  /* background-color: blue; */

  float: right;
  position:relative;
  top: 20px;
  right: 50px;
}

.userLoginContainerSubCard2Form{
  width:100%;
  height: 350px;
  /* background-color: red; */
  margin-top:50px;
}



.userLoginContainerSubCard2Form input {
  background-color : #1E2026; 
  border: none;
  padding:10px;
  color:#eaecef;
  padding: 15px;
}

.userLoginContainerSubCard2Form input:focus {
  background-color : #1E2026; 
  outline: none;
  border: none;
  box-shadow:  1px 1px 5px #F0B90B;
  color:#eaecef;
}

.userLoginContainerSubCard2Form input::placeholder  {
  color : #eaecef;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-size:14px;
  
}

.userLoginContainerSubCard2Form h3{
  text-align: center;
  padding: 8px;
  color: #F0B90B;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;

}

.forgetPwd{
  color:#eaecef;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  padding: 18px;
  cursor: pointer;

  float: left;
}


.noAcct{
  color:#eaecef;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  padding: 18px;
  cursor: pointer;

  float: right;
}


.noAcct a{
  text-decoration: none;
  color:#eaecef;
  font-size: 14px;
}

.noAcct a:hover{
  color:#FCD535;
}

.forgetPwd a{
  text-decoration: none;
  color:#eaecef;
  font-size: 14px;
}

.forgetPwd a:hover{
  color:#FCD535;
}

.loginFormSubContainer{
  width:100%;
}






/* registerContainer */

.registerContainer{
  width:100%;
  height: 800px;
  /* background-color: red; */
}



.registeruserLoginContainer{
  width:100%;
  height: 450px;
  /* margin-top: */
  /* background-color: red; */
}

.registeruserLoginContainerSub{
  width:100%;

}

.registeruserLoginContainerSubCard1{
  width:30%;
  height:400px;
  /* background-color: red; */
  background: url("./images/bg/register.svg");
  background-repeat:no-repeat;
  background-size:contain;
  background-position:center;

  float: left;
  position:relative;
  top: 50px;
  left: 80px;
}

.registeruserLoginContainerSubCard2{
  width:50%;
  height:400px;
  /* background-color: blue; */

  float: right;
  position:relative;
  top: 20px;
  right: 50px;
}

.registeruserLoginContainerSubCard2Form{
  width:100%;
  height: 350px;
  /* background-color: red; */
  margin-top:30px;
}



.registeruserLoginContainerSubCard2Form input {
  background-color : #1E2026; 
  border: none;
  padding:10px;
  color:#eaecef;
  padding: 15px;
}

.registeruserLoginContainerSubCard2Form input:focus {
  background-color : #1E2026; 
  outline: none;
  border: none;
  box-shadow:  1px 1px 5px #F0B90B;
  color:#eaecef;
}

.registeruserLoginContainerSubCard2Form input::placeholder  {
  color : #eaecef;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-size:14px;
  
}

.registeruserLoginContainerSubCard2Form h3{
  text-align: center;
  padding: 8px;
  color: #F0B90B;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;

}

.iagree{
  padding: 0px 0px 0px 9px;
  font-size:14px;
  line-height: 30px;
}







































/* media query */

/* first media query  */
@media only screen and (min-width: 320px) and (max-width: 359px)
{
  /* begin media query */


  


.navBarContainerx{
  width:50px;
  height: 50px;
  line-height: 60px;
  float: right;
  position: relative;
  top:-65px;
  right:25px;
  display: block;


}




    /* nav section mobile */
  .logoSection{
    width: 50%;
    height: auto;
    /* background-color: red; */
    margin-top: 0px;
  
    float: none;
    margin-left: 5px;
   
  }
  
  .navItems{
    display: none;
    margin-top: 0px;
    float: none;
    margin-right:0px;
  }


  .logoSection h2{
    line-height: 65px;  
  }



  .showCaseContainer{
    width:100%;
    height: 520px;
    /* background-color: red; */
    margin-top: 30px;
  
  }


  /* show case section */
.showCaseContainer_Sub_1_Container{
  width:100%;
  height: 450px;
  /* background-color: red; */
  margin-left:0px;
  padding-top: 80px;

}

.showCaseContainer_Sub_1_Container h3{
  font-size:30px;
  padding: 10px;
  text-align: center;
}


.showCaseContainer_Sub_1_Container p{
  padding: 10px;
  font-size:16px;
  /* text-wrap: wrap; */
  text-align: center;
}


.showCaseButton_1{
  width: 90%;
  float: none;
  margin: auto;
  margin-bottom: 18px;

}


.showCaseButton_2{
  width: 90%;
  float: none;
  margin: auto;

}


.showCaseParaBelow{
   text-align: center;
   font-weight: bold;
  
}




/* about mobile */
.aboutSection{
  width: 100%;
  height:1600px;
  /* background-color: red; */
}


.aboutSection_sub2_1{
  width:100%;
  height:400px;
  /* background-color: pink; */
  float: none;
  margin-left:0px;
}

.aboutSection_sub2_2{
  width:100%;
  height:auto;
  float: none;
  margin-right:0px;
  padding: 15px;
  text-align: center;
}


.companyCert{
  width: 80%;
  margin: auto;
}



.whyChooseUsSection{
  width: 100%;
  height: 1880px;
  /* background-color: red; */
  background-color: #1E2026;
 
}

.whyChooseUsSectionContainer{
  width:100%;
  height: 1300px;
  /* background-color: pink; */
}


.whyChooseUsSectionContainerHeading{
  width: 100%;
  height: 170px;
  margin: auto;
 
}


.whyChooseUsSectionContainerDetailsCard{
  width:95%;
  height: 250px;
  background-color: #181A20;
  float: none;
  margin-right: 0px;
  margin-bottom: 25px;
  position: relative;
  left: 10px;
  
}


.sectionFourContainer{
  width:100%;
  height: 1000px;
}



.sectionFourContainerSubCard1{
  width:95%;
  height: 400px;
  float:none ;
  margin-left:0px;
  margin-bottom: 40px;
  
}

.sectionFourContainerSubCard2{
  width:95%;
  height: 400px;
  /* background-color: blue; */
  float:none ;
  margin-right:0px;
}

.sectionFourContainerSubCard2Details h3{
  padding: 15px;
  text-align: center;
}


.stepsContainer h5{
  /* padding: 15px; */
  text-align: center;
}

.stepsContainer p{
  /* padding: 15px; */
  text-align: center;
}


.faqContainer{
  width:100%;
  height: auto;
  /* background-color: red; */
}


.faqContainerSub{
  width: 90%;
  height: auto;
  margin:auto;
}


.referralContainer{
  width:100%;
  height:250px;
  /* background-color: red; */
}


.referralContainerSub{
  width:90%;
  height: 240px;
  background-color: #1E2026;
  margin:auto;
  margin-top:30px;
  padding: 20px;

}


.cryptoDebitCardContainer{
  width: 100%;
  height: 650px;
  /* background-color: red; */
}


.cryptoDebitCardContainerSubCard1{
  width:80%;
  height: 300px;
  float: none;
  margin-left:20px;
  margin-bottom: 30px;
}

.cryptoDebitCardContainerSubCard2{
  width:90%;
  height: auto;
  /* background-color: red; */
  padding: 10px;
  text-align: center;
  margin-top: 0px;
  float: none;
  margin-right:0px;
  margin: auto;
}


.testimonailContainer{
  width:100%;
  height: auto;
  background-color: #1E2026;
}


.testimonailContainerSub p{
  padding: 10px;
}


.testimonailCard{
  width: 95%;
  height:200px;
  
  float: none;
  margin-right: 0px;
  margin-bottom: 15px;
  position: relative;
  left:10px;
  top:20px;
}



.footerContainer{
  width:100%;
  height: auto;
}


.footerContainerSubCard{
  width: 90%;
  height: 190px;
  /* background-color: red; */

  float: none;
  margin-right:30px;
  position: relative;
  left:5px;
  top:15px;
}

.footerContainerSubCard h4{
  padding-left: 5px;
}

.footerContainerSubCard ul li a{
line-height: 30px;
}



/* about mobile */

.aboutSection1{
  width:100%;
  height: 600px;
  /* background:red;   */
}


.aboutSection1SubCard{
  width:95%;
  height:150px;
  background-color: #1E2026;
 
  float: none;
  margin-right:0px;
  margin-bottom: 20px;

  position: relative;
  top:12px;
  left:10px;

  
}




.ourTeamContainer{
  width:100%;
  height: 800px;
  /* background-color: red; */
  margin-top:30px;
}


.ourTeamContainerSub{
  width:100%;
  height: auto;
  /* background-color: blue; */
  margin: auto;
  margin-top:0px;
}

.ourTeamContainerSubCard{
  width: 95%;
  height: 160px;
  background-color: #1E2026;
  float: none;
  margin-right:0px;
  position: relative;
  left:10px;
}




/* plans */

.plansContainerSubCard{
  width:95%;
  height: 380px;
  background-color: #1E2026;

  float: none;
  margin-right:0px;
  margin-bottom:50px;
  /* margin-left:40px; */
  position: relative;
  left:10px;
  top:20px;

}




/* contact */

.contactContainerContent1{
  width:95%;
  height: auto;
  margin: auto;
  margin-top:50px;
  
}



.otherContactDetails{
  width: 100%;
  height: 650px;
  /* background-color: blue; */
}

.otherContactDetailsCard{
  width:95%;
  height: 200px;
  background-color: #1E2026;

  float: none;
  margin-right:0px;
  position: relative;
  left: 10px;
}



/* login mobile */


.userLoginContainer{
  width:100%;
  height: auto;
  /* background-color: red; */

  margin-bottom: 80px;
}


.userLoginContainerSubCard1{
  display: none;
}


.userLoginContainerSubCard2{
  width:90%;
  height:400px;
  /* background-color: blue; */

  float: none;
  position:relative;
  top: 20px;
  right: 0px;
  /* left: 10px; */
  margin: auto;
}


.forgetPwd{
  color:#eaecef;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  padding: 8px;
  cursor: pointer;
  float: none;
}


.noAcct{
  color:#eaecef;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  padding: 8px;
  padding-top: 40px;
  cursor: pointer;
  float: none;
}


/* register mobile */
.registerContainer{
  width:100%;
  height: 900px;
  /* background-color: red; */
  /* margin-bottom: 80px; */
}


.registeruserLoginContainer{
  width:100%;
  height: auto;
  
}


.registeruserLoginContainerSubCard1{
 display: none;
}

.registeruserLoginContainerSubCard2{
  width:90%;
  height:auto;
  /* background-color: blue; */

  float: none;
  position:relative;
  top: 20px;
  right: 0px;
  margin: auto;
}









  /* end media query */
}



/* second media query */
@media only screen and (min-width: 360px) and (max-width: 374px)
{
   /* begin media query */



.navBarContainerx{
  width:50px;
  height: 50px;
  line-height: 60px;
  float: right;
  position: relative;
  top:-65px;
  right:25px;
  display: block;


}







  /* nav section mobile */
  .logoSection{
    width: 50%;
    height: auto;
    /* background-color: red; */
    margin-top: 0px;
  
    float: none;
    margin-left: 5px;
   
  }
  
  .navItems{
    display: none;
    margin-top: 0px;
    float: none;
    margin-right:0px;
  }


  .logoSection h2{
    line-height: 65px;  
  }




  /* show case section */
.showCaseContainer_Sub_1_Container{
  width:100%;
  height: 450px;
  /* background-color: red; */
  margin-left:0px;
  padding-top: 80px;

}

.showCaseContainer_Sub_1_Container h3{
  font-size:30px;
  padding: 10px;
  text-align: center;
}


.showCaseContainer_Sub_1_Container p{
  padding: 10px;
  font-size:16px;
  /* text-wrap: wrap; */
  text-align: center;
}


.showCaseButton_1{
  width: 90%;
  float: none;
  margin: auto;
  margin-bottom: 18px;

}


.showCaseButton_2{
  width: 90%;
  float: none;
  margin: auto;

}


.showCaseParaBelow{
   text-align: center;
   font-weight: bold;
}




/* about mobile */
.aboutSection{
  width: 100%;
  height:1450px;
  /* background-color: red; */
}


.aboutSection_sub2_1{
  width:100%;
  height:400px;
  /* background-color: pink; */
  float: none;
  margin-left:0px;
}

.aboutSection_sub2_2{
  width:100%;
  height:auto;
  float: none;
  margin-right:0px;
  padding: 15px;
  text-align: center;
}


.companyCert{
  width: 80%;
  margin: auto;
}



.whyChooseUsSection{
  width: 100%;
  height: 1880px;
  /* background-color: red; */
  background-color: #1E2026;
 
}

.whyChooseUsSectionContainer{
  width:100%;
  height: 1300px;
  /* background-color: pink; */
}


.whyChooseUsSectionContainerHeading{
  width: 100%;
  height: 140px;
  margin: auto;
 
}


.whyChooseUsSectionContainerDetailsCard{
  width:95%;
  height: 250px;
  background-color: #181A20;
  float: none;
  margin-right: 0px;
  margin-bottom: 25px;
  position: relative;
  left: 10px;
  
}


.sectionFourContainer{
  width:100%;
  height: 1000px;
}



.sectionFourContainerSubCard1{
  width:95%;
  height: 400px;
  float:none ;
  margin-left:0px;
  margin-bottom: 40px;
  
}

.sectionFourContainerSubCard2{
  width:95%;
  height: 400px;
  /* background-color: blue; */
  float:none ;
  margin-right:0px;
}

.sectionFourContainerSubCard2Details h3{
  padding: 15px;
  text-align: center;
}


.stepsContainer h5{
  /* padding: 15px; */
  text-align: center;
}

.stepsContainer p{
  /* padding: 15px; */
  text-align: center;
}


.faqContainer{
  width:100%;
  height: auto;
  /* background-color: red; */
}


.faqContainerSub{
  width: 90%;
  height: auto;
  margin:auto;
}


.referralContainer{
  width:100%;
  height:250px;
  /* background-color: red; */
}


.referralContainerSub{
  width:90%;
  height: 240px;
  background-color: #1E2026;
  margin:auto;
  margin-top:30px;
  padding: 20px;

}


.cryptoDebitCardContainer{
  width: 100%;
  height: 650px;
  /* background-color: red; */
}


.cryptoDebitCardContainerSubCard1{
  width:80%;
  height: 300px;
  float: none;
  margin-left:20px;
  margin-bottom: 30px;
}

.cryptoDebitCardContainerSubCard2{
  width:90%;
  height: auto;
  /* background-color: red; */
  padding: 10px;
  text-align: center;
  margin-top: 0px;
  float: none;
  margin-right:0px;
  margin: auto;
}


.testimonailContainer{
  width:100%;
  height: auto;
  background-color: #1E2026;
}


.testimonailContainerSub p{
  padding: 10px;
}


.testimonailCard{
  width: 95%;
  height:200px;
  
  float: none;
  margin-right: 0px;
  margin-bottom: 15px;
  position: relative;
  left:10px;
  top:20px;
}



.footerContainer{
  width:100%;
  height: auto;
}


.footerContainerSubCard{
  width: 90%;
  height: 190px;
  /* background-color: red; */

  float: none;
  margin-right:30px;
  position: relative;
  left:5px;
  top:15px;
}

.footerContainerSubCard h4{
  padding-left: 5px;
}

.footerContainerSubCard ul li a{
line-height: 30px;
}



/* about mobile */

.aboutSection1{
  width:100%;
  height: 600px;
  /* background:red;   */
}


.aboutSection1SubCard{
  width:95%;
  height:150px;
  background-color: #1E2026;
 
  float: none;
  margin-right:0px;
  margin-bottom: 20px;

  position: relative;
  top:12px;
  left:10px;

  
}




.ourTeamContainer{
  width:100%;
  height: 800px;
  /* background-color: red; */
  margin-top:30px;
}


.ourTeamContainerSub{
  width:100%;
  height: auto;
  /* background-color: blue; */
  margin: auto;
  margin-top:0px;
}

.ourTeamContainerSubCard{
  width: 95%;
  height: 160px;
  background-color: #1E2026;
  float: none;
  margin-right:0px;
  position: relative;
  left:10px;
}




/* plans */

.plansContainerSubCard{
  width:95%;
  height: 380px;
  background-color: #1E2026;

  float: none;
  margin-right:0px;
  margin-bottom:50px;
  /* margin-left:40px; */
  position: relative;
  left:10px;
  top:20px;

}




/* contact */

.contactContainerContent1{
  width:95%;
  height: auto;
  margin: auto;
  margin-top:50px;
  
}



.otherContactDetails{
  width: 100%;
  height: 650px;
  /* background-color: blue; */
}

.otherContactDetailsCard{
  width:95%;
  height: 200px;
  background-color: #1E2026;

  float: none;
  margin-right:0px;
  position: relative;
  left: 10px;
}



/* login mobile */


.userLoginContainer{
  width:100%;
  height: auto;
  /* background-color: red; */

  margin-bottom: 80px;
}


.userLoginContainerSubCard1{
  display: none;
}


.userLoginContainerSubCard2{
  width:90%;
  height:400px;
  /* background-color: blue; */

  float: none;
  position:relative;
  top: 20px;
  right: 0px;
  /* left: 10px; */
  margin: auto;
}


.forgetPwd{
  color:#eaecef;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  padding: 8px;
  cursor: pointer;
  float: none;
}


.noAcct{
  color:#eaecef;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  padding: 8px;
  padding-top: 40px;
  cursor: pointer;
  float: none;
}


/* register mobile */
.registerContainer{
  width:100%;
  height: 900px;
  /* background-color: red; */
  /* margin-bottom: 80px; */
}


.registeruserLoginContainer{
  width:100%;
  height: auto;
  
}


.registeruserLoginContainerSubCard1{
 display: none;
}

.registeruserLoginContainerSubCard2{
  width:90%;
  height:auto;
  /* background-color: blue; */

  float: none;
  position:relative;
  top: 20px;
  right: 0px;
  margin: auto;
}







  /* end media query */
}





/* third media query */
@media only screen and (min-width: 375px) and (max-width: 480px)
{

   /* begin media query */


/* 
  .navMobile{
    width: 50px;
    height: 50px;
    background-color: red ;
    float: right;
  } */




.navBarContainerx{
  width:50px;
  height: 50px;
  line-height: 60px;
  float: right;
  position: relative;
  top:-65px;
  right:25px;
  display: block;


}




  /* nav section mobile */
  .logoSection{
    width: 50%;
    height: auto;
    /* background-color: red; */
    margin-top: 0px;
  
    float: none;
    margin-left: 5px;
   
  }
  
  .navItems{
    display: none;
    margin-top: 0px;
    float: none;
    margin-right:0px;
  }


  .logoSection h2{
    line-height: 65px;  
  }




  /* show case section */
.showCaseContainer_Sub_1_Container{
  width:100%;
  height: 450px;
  /* background-color: red; */
  margin-left:0px;
  padding-top: 80px;

}

.showCaseContainer_Sub_1_Container h3{
  font-size:30px;
  padding: 10px;
  text-align: center;
}


.showCaseContainer_Sub_1_Container p{
  padding: 10px;
  font-size:16px;
  /* text-wrap: wrap; */
  text-align: center;
}


.showCaseButton_1{
  width: 90%;
  float: none;
  margin: auto;
  margin-bottom: 18px;

}


.showCaseButton_2{
  width: 90%;
  float: none;
  margin: auto;

}


.showCaseParaBelow{
   text-align: center;
   font-weight: bold;
}




/* about mobile */
.aboutSection{
  width: 100%;
  height:1450px;
  /* background-color: red; */
}


.aboutSection_sub2_1{
  width:100%;
  height:400px;
  /* background-color: pink; */
  float: none;
  margin-left:0px;
}

.aboutSection_sub2_2{
  width:100%;
  height:auto;
  float: none;
  margin-right:0px;
  padding: 15px;
  text-align: center;
}


.companyCert{
  width: 80%;
  margin: auto;
}



.whyChooseUsSection{
  width: 100%;
  height: 1880px;
  /* background-color: red; */
  background-color: #1E2026;
 
}

.whyChooseUsSectionContainer{
  width:100%;
  height: 1300px;
  /* background-color: pink; */
}


.whyChooseUsSectionContainerHeading{
  width: 100%;
  height: 140px;
  margin: auto;
 
}


.whyChooseUsSectionContainerDetailsCard{
  width:95%;
  height: 250px;
  background-color: #181A20;
  float: none;
  margin-right: 0px;
  margin-bottom: 25px;
  position: relative;
  left: 10px;
  
}


.sectionFourContainer{
  width:100%;
  height: 1000px;
}



.sectionFourContainerSubCard1{
  width:95%;
  height: 400px;
  float:none ;
  margin-left:0px;
  margin-bottom: 40px;
  
}

.sectionFourContainerSubCard2{
  width:95%;
  height: 400px;
  /* background-color: blue; */
  float:none ;
  margin-right:0px;
}

.sectionFourContainerSubCard2Details h3{
  padding: 15px;
  text-align: center;
}


.stepsContainer h5{
  /* padding: 15px; */
  text-align: center;
}

.stepsContainer p{
  /* padding: 15px; */
  text-align: center;
}


.faqContainer{
  width:100%;
  height: auto;
  /* background-color: red; */
}


.faqContainerSub{
  width: 90%;
  height: auto;
  margin:auto;
}


.referralContainer{
  width:100%;
  height:250px;
  /* background-color: red; */
}


.referralContainerSub{
  width:90%;
  height: 240px;
  background-color: #1E2026;
  margin:auto;
  margin-top:30px;
  padding: 20px;

}


.cryptoDebitCardContainer{
  width: 100%;
  height: 650px;
  /* background-color: red; */
}


.cryptoDebitCardContainerSubCard1{
  width:80%;
  height: 300px;
  float: none;
  margin-left:20px;
  margin-bottom: 30px;
}

.cryptoDebitCardContainerSubCard2{
  width:90%;
  height: auto;
  /* background-color: red; */
  padding: 10px;
  text-align: center;
  margin-top: 0px;
  float: none;
  margin-right:0px;
  margin: auto;
}


.testimonailContainer{
  width:100%;
  height: auto;
  background-color: #1E2026;
}


.testimonailContainerSub p{
  padding: 10px;
}


.testimonailCard{
  width: 95%;
  height:200px;
  
  float: none;
  margin-right: 0px;
  margin-bottom: 15px;
  position: relative;
  left:10px;
  top:20px;
}



.footerContainer{
  width:100%;
  height: auto;
}


.footerContainerSubCard{
  width: 90%;
  height: 190px;
  /* background-color: red; */

  float: none;
  margin-right:30px;
  position: relative;
  left:5px;
  top:15px;
}

.footerContainerSubCard h4{
  padding-left: 5px;
}

.footerContainerSubCard ul li a{
line-height: 30px;
}



/* about mobile */

.aboutSection1{
  width:100%;
  height: 600px;
  /* background:red;   */
}


.aboutSection1SubCard{
  width:95%;
  height:150px;
  background-color: #1E2026;
 
  float: none;
  margin-right:0px;
  margin-bottom: 20px;

  position: relative;
  top:12px;
  left:10px;

  
}




.ourTeamContainer{
  width:100%;
  height: 800px;
  /* background-color: red; */
  margin-top:30px;
}


.ourTeamContainerSub{
  width:100%;
  height: auto;
  /* background-color: blue; */
  margin: auto;
  margin-top:0px;
}

.ourTeamContainerSubCard{
  width: 95%;
  height: 160px;
  background-color: #1E2026;
  float: none;
  margin-right:0px;
  position: relative;
  left:10px;
}




/* plans */

.plansContainerSubCard{
  width:95%;
  height: 380px;
  background-color: #1E2026;

  float: none;
  margin-right:0px;
  margin-bottom:50px;
  /* margin-left:40px; */
  position: relative;
  left:10px;
  top:20px;

}




/* contact */

.contactContainerContent1{
  width:95%;
  height: auto;
  margin: auto;
  margin-top:50px;
  
}



.otherContactDetails{
  width: 100%;
  height: 650px;
  /* background-color: blue; */
}

.otherContactDetailsCard{
  width:95%;
  height: 200px;
  background-color: #1E2026;

  float: none;
  margin-right:0px;
  position: relative;
  left: 10px;
}



/* login mobile */


.userLoginContainer{
  width:100%;
  height: auto;
  /* background-color: red; */

  margin-bottom: 80px;
}


.userLoginContainerSubCard1{
  display: none;
}


.userLoginContainerSubCard2{
  width:90%;
  height:400px;
  /* background-color: blue; */

  float: none;
  position:relative;
  top: 20px;
  right: 0px;
  /* left: 10px; */
  margin: auto;
}


.forgetPwd{
  color:#eaecef;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  padding: 8px;
  cursor: pointer;
  float: none;
}


.noAcct{
  color:#eaecef;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  padding: 8px;
  padding-top: 40px;
  cursor: pointer;
  float: none;
}


/* register mobile */
.registerContainer{
  width:100%;
  height: 900px;
  /* background-color: red; */
  /* margin-bottom: 80px; */
}


.registeruserLoginContainer{
  width:100%;
  height: auto;
  
}


.registeruserLoginContainerSubCard1{
 display: none;
}

.registeruserLoginContainerSubCard2{
  width:90%;
  height:auto;
  /* background-color: blue; */

  float: none;
  position:relative;
  top: 20px;
  right: 0px;
  margin: auto;
}











   /* end media query */
}










/* first tab media query */



@media only screen and (min-width: 481px) and (max-width: 768px)
{
   /* begin media query */




.navBarContainerx{
  width:50px;
  height: 50px;
  line-height: 60px;
  float: right;
  position: relative;
  top:-65px;
  right:25px;
  display: block;


}




/* 
  .navMobile{
    width: 50px;
    height: 50px;
    background-color: red ;
    float: right;
  } */


  /* nav section mobile */
  .logoSection{
    width: 50%;
    height: auto;
    /* background-color: red; */
    margin-top: 0px;
  
    float: none;
    margin-left: 5px;
   
  }
  
  .navItems{
    display: none;
    margin-top: 0px;
    float: none;
    margin-right:0px;
  }


  .logoSection h2{
    line-height: 65px;  
  }




  /* show case section */
.showCaseContainer_Sub_1_Container{
  width:100%;
  height: 450px;
  /* background-color: red; */
  margin-left:0px;
  padding-top: 80px;

}

.showCaseContainer_Sub_1_Container h3{
  font-size:30px;
  padding: 10px;
  text-align: center;
}


.showCaseContainer_Sub_1_Container p{
  padding: 10px;
  font-size:16px;
  /* text-wrap: wrap; */
  text-align: center;
}


.showCaseButton_1{
  width: 90%;
  float: none;
  margin: auto;
  margin-bottom: 18px;

}


.showCaseButton_2{
  width: 90%;
  float: none;
  margin: auto;

}


.showCaseParaBelow{
   text-align: center;
   font-weight: bold;
}




/* about mobile */
.aboutSection{
  width: 100%;
  height:1250px;
  /* background-color: red; */
}


.aboutSection_sub2_1{
  width:100%;
  height:400px;
  /* background-color: pink; */
  float: none;
  margin-left:0px;
}

.aboutSection_sub2_2{
  width:100%;
  height:auto;
  float: none;
  margin-right:0px;
  padding: 15px;
  text-align: center;
}


.companyCert{
  width: 80%;
  margin: auto;
}



.whyChooseUsSection{
  width: 100%;
  height: 1880px;
  /* background-color: red; */
  background-color: #1E2026;
 
}

.whyChooseUsSectionContainer{
  width:100%;
  height: 1300px;
  /* background-color: pink; */
}


.whyChooseUsSectionContainerHeading{
  width: 100%;
  height: 140px;
  margin: auto;
 
}


.whyChooseUsSectionContainerDetailsCard{
  width:95%;
  height: 250px;
  background-color: #181A20;
  float: none;
  margin-right: 0px;
  margin-bottom: 25px;
  position: relative;
  left: 10px;
  
}


.sectionFourContainer{
  width:100%;
  height: 1000px;
}



.sectionFourContainerSubCard1{
  width:95%;
  height: 400px;
  float:none ;
  margin-left:0px;
  margin-bottom: 40px;
  
}

.sectionFourContainerSubCard2{
  width:95%;
  height: 400px;
  /* background-color: blue; */
  float:none ;
  margin-right:0px;
}

.sectionFourContainerSubCard2Details h3{
  padding: 15px;
  text-align: center;
}


.stepsContainer h5{
  /* padding: 15px; */
  text-align: center;
}

.stepsContainer p{
  /* padding: 15px; */
  text-align: center;
}


.faqContainer{
  width:100%;
  height: auto;
  /* background-color: red; */
}


.faqContainerSub{
  width: 90%;
  height: auto;
  margin:auto;
}


.referralContainer{
  width:100%;
  height:250px;
  /* background-color: red; */
}


.referralContainerSub{
  width:90%;
  height: 240px;
  background-color: #1E2026;
  margin:auto;
  margin-top:30px;
  padding: 20px;

}


.cryptoDebitCardContainer{
  width: 100%;
  height: 650px;
  /* background-color: red; */
}


.cryptoDebitCardContainerSubCard1{
  width:80%;
  height: 300px;
  float: none;
  margin-left:20px;
  margin-bottom: 30px;
}

.cryptoDebitCardContainerSubCard2{
  width:90%;
  height: auto;
  /* background-color: red; */
  padding: 10px;
  text-align: center;
  margin-top: 0px;
  float: none;
  margin-right:0px;
  margin: auto;
}


.testimonailContainer{
  width:100%;
  height: auto;
  background-color: #1E2026;
}


.testimonailContainerSub p{
  padding: 10px;
}


.testimonailCard{
  width: 95%;
  height:200px;
  
  float: none;
  margin-right: 0px;
  margin-bottom: 15px;
  position: relative;
  left:10px;
  top:20px;
}



.footerContainer{
  width:100%;
  height: auto;
}


.footerContainerSubCard{
  width: 90%;
  height: 190px;
  /* background-color: red; */

  float: none;
  margin-right:30px;
  position: relative;
  left:5px;
  top:15px;
}

.footerContainerSubCard h4{
  padding-left: 5px;
}

.footerContainerSubCard ul li a{
line-height: 30px;
}



/* about mobile */

.aboutSection1{
  width:100%;
  height: 600px;
  /* background:red;   */
}


.aboutSection1SubCard{
  width:95%;
  height:150px;
  background-color: #1E2026;
 
  float: none;
  margin-right:0px;
  margin-bottom: 20px;

  position: relative;
  top:12px;
  left:10px;

  
}




.ourTeamContainer{
  width:100%;
  height: 800px;
  /* background-color: red; */
  margin-top:30px;
}


.ourTeamContainerSub{
  width:100%;
  height: auto;
  /* background-color: blue; */
  margin: auto;
  margin-top:0px;
}

.ourTeamContainerSubCard{
  width: 95%;
  height: 160px;
  background-color: #1E2026;
  float: none;
  margin-right:0px;
  position: relative;
  left:10px;
}




/* plans */

.plansContainerSubCard{
  width:95%;
  height: 380px;
  background-color: #1E2026;

  float: none;
  margin-right:0px;
  margin-bottom:50px;
  /* margin-left:40px; */
  position: relative;
  left:10px;
  top:20px;

}




/* contact */

.contactContainerContent1{
  width:95%;
  height: auto;
  margin: auto;
  margin-top:50px;
  
}



.otherContactDetails{
  width: 100%;
  height: 650px;
  /* background-color: blue; */
}

.otherContactDetailsCard{
  width:95%;
  height: 200px;
  background-color: #1E2026;

  float: none;
  margin-right:0px;
  position: relative;
  left: 10px;
}



/* login mobile */


.userLoginContainer{
  width:100%;
  height: auto;
  /* background-color: red; */

  margin-bottom: 80px;
}


.userLoginContainerSubCard1{
  display: none;
}


.userLoginContainerSubCard2{
  width:90%;
  height:400px;
  /* background-color: blue; */

  float: none;
  position:relative;
  top: 20px;
  right: 0px;
  /* left: 10px; */
  margin: auto;
}


.forgetPwd{
  color:#eaecef;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  padding: 8px;
  cursor: pointer;
  float: none;
}


.noAcct{
  color:#eaecef;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  padding: 8px;
  padding-top: 40px;
  cursor: pointer;
  float: none;
}


/* register mobile */
.registerContainer{
  width:100%;
  height: 900px;
  /* background-color: red; */
  /* margin-bottom: 80px; */
}


.registeruserLoginContainer{
  width:100%;
  height: auto;
  
}


.registeruserLoginContainerSubCard1{
 display: none;
}

.registeruserLoginContainerSubCard2{
  width:90%;
  height:auto;
  /* background-color: blue; */

  float: none;
  position:relative;
  top: 20px;
  right: 0px;
  margin: auto;
}



   /* end media query */
}




@media only screen and (min-width: 769px) and (max-width: 884px)
{
  /* begin media query */




.navBarContainerx{
  width:50px;
  height: 50px;
  line-height: 60px;
  float: right;
  position: relative;
  top:-65px;
  right:25px;
  display: block;


}


  

/* 
  .navMobile{
    width: 50px;
    height: 50px;
    background-color: red ;
    float: right;
  } */


  /* nav section mobile */
  .logoSection{
    width: 50%;
    height: auto;
    /* background-color: red; */
    margin-top: 0px;
  
    float: none;
    margin-left: 5px;
   
  }
  
  .navItems{
    display: none;
    margin-top: 0px;
    float: none;
    margin-right:0px;
  }


  .logoSection h2{
    line-height: 65px;  
  }




  /* show case section */
.showCaseContainer_Sub_1_Container{
  width:100%;
  height: 450px;
  /* background-color: red; */
  margin-left:0px;
  padding-top: 80px;

}

.showCaseContainer_Sub_1_Container h3{
  font-size:30px;
  padding: 10px;
  text-align: center;
}


.showCaseContainer_Sub_1_Container p{
  padding: 10px;
  font-size:16px;
  /* text-wrap: wrap; */
  text-align: center;
}


.showCaseButton_1{
  width: 90%;
  float: none;
  margin: auto;
  margin-bottom: 18px;

}


.showCaseButton_2{
  width: 90%;
  float: none;
  margin: auto;

}


.showCaseParaBelow{
   text-align: center;
   font-weight: bold;
}




/* about mobile */
.aboutSection{
  width: 100%;
  height:1250px;
  /* background-color: red; */
}


.aboutSection_sub2_1{
  width:100%;
  height:400px;
  /* background-color: pink; */
  float: none;
  margin-left:0px;
}

.aboutSection_sub2_2{
  width:100%;
  height:auto;
  float: none;
  margin-right:0px;
  padding: 15px;
  text-align: center;
}


.companyCert{
  width: 80%;
  margin: auto;
}



.whyChooseUsSection{
  width: 100%;
  height: 1880px;
  /* background-color: red; */
  background-color: #1E2026;
 
}

.whyChooseUsSectionContainer{
  width:100%;
  height: 1300px;
  /* background-color: pink; */
}


.whyChooseUsSectionContainerHeading{
  width: 100%;
  height: 140px;
  margin: auto;
 
}


.whyChooseUsSectionContainerDetailsCard{
  width:95%;
  height: 250px;
  background-color: #181A20;
  float: none;
  margin-right: 0px;
  margin-bottom: 25px;
  position: relative;
  left: 10px;
  
}


.sectionFourContainer{
  width:100%;
  height: 1000px;
}



.sectionFourContainerSubCard1{
  width:95%;
  height: 400px;
  float:none ;
  margin-left:0px;
  margin-bottom: 40px;
  
}

.sectionFourContainerSubCard2{
  width:95%;
  height: 400px;
  /* background-color: blue; */
  float:none ;
  margin-right:0px;
}

.sectionFourContainerSubCard2Details h3{
  padding: 15px;
  text-align: center;
}


.stepsContainer h5{
  /* padding: 15px; */
  text-align: center;
}

.stepsContainer p{
  /* padding: 15px; */
  text-align: center;
}


.faqContainer{
  width:100%;
  height: auto;
  /* background-color: red; */
}


.faqContainerSub{
  width: 90%;
  height: auto;
  margin:auto;
}


.referralContainer{
  width:100%;
  height:250px;
  /* background-color: red; */
}


.referralContainerSub{
  width:90%;
  height: 240px;
  background-color: #1E2026;
  margin:auto;
  margin-top:30px;
  padding: 20px;

}


.cryptoDebitCardContainer{
  width: 100%;
  height: 650px;
  /* background-color: red; */
}


.cryptoDebitCardContainerSubCard1{
  width:80%;
  height: 300px;
  float: none;
  margin-left:20px;
  margin-bottom: 30px;
}

.cryptoDebitCardContainerSubCard2{
  width:90%;
  height: auto;
  /* background-color: red; */
  padding: 10px;
  text-align: center;
  margin-top: 0px;
  float: none;
  margin-right:0px;
  margin: auto;
}


.testimonailContainer{
  width:100%;
  height: auto;
  background-color: #1E2026;
}


.testimonailContainerSub p{
  padding: 10px;
}


.testimonailCard{
  width: 95%;
  height:200px;
  
  float: none;
  margin-right: 0px;
  margin-bottom: 15px;
  position: relative;
  left:10px;
  top:20px;
}



.footerContainer{
  width:100%;
  height: auto;
}


.footerContainerSubCard{
  width: 90%;
  height: 190px;
  /* background-color: red; */

  float: none;
  margin-right:30px;
  position: relative;
  left:5px;
  top:15px;
}

.footerContainerSubCard h4{
  padding-left: 5px;
}

.footerContainerSubCard ul li a{
line-height: 30px;
}



/* about mobile */

.aboutSection1{
  width:100%;
  height: 600px;
  /* background:red;   */
}


.aboutSection1SubCard{
  width:95%;
  height:150px;
  background-color: #1E2026;
 
  float: none;
  margin-right:0px;
  margin-bottom: 20px;

  position: relative;
  top:12px;
  left:10px;

  
}




.ourTeamContainer{
  width:100%;
  height: 800px;
  /* background-color: red; */
  margin-top:30px;
}


.ourTeamContainerSub{
  width:100%;
  height: auto;
  /* background-color: blue; */
  margin: auto;
  margin-top:0px;
}

.ourTeamContainerSubCard{
  width: 95%;
  height: 160px;
  background-color: #1E2026;
  float: none;
  margin-right:0px;
  position: relative;
  left:10px;
}




/* plans */

.plansContainerSubCard{
  width:95%;
  height: 380px;
  background-color: #1E2026;

  float: none;
  margin-right:0px;
  margin-bottom:50px;
  /* margin-left:40px; */
  position: relative;
  left:10px;
  top:20px;

}




/* contact */

.contactContainerContent1{
  width:95%;
  height: auto;
  margin: auto;
  margin-top:50px;
  
}



.otherContactDetails{
  width: 100%;
  height: 650px;
  /* background-color: blue; */
}

.otherContactDetailsCard{
  width:95%;
  height: 200px;
  background-color: #1E2026;

  float: none;
  margin-right:0px;
  position: relative;
  left: 10px;
}



/* login mobile */


.userLoginContainer{
  width:100%;
  height: auto;
  /* background-color: red; */

  margin-bottom: 80px;
}


.userLoginContainerSubCard1{
  display: none;
}


.userLoginContainerSubCard2{
  width:90%;
  height:400px;
  /* background-color: blue; */

  float: none;
  position:relative;
  top: 20px;
  right: 0px;
  /* left: 10px; */
  margin: auto;
}


.forgetPwd{
  color:#eaecef;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  padding: 8px;
  cursor: pointer;
  float: none;
}


.noAcct{
  color:#eaecef;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  padding: 8px;
  padding-top: 40px;
  cursor: pointer;
  float: none;
}


/* register mobile */
.registerContainer{
  width:100%;
  height: 900px;
  /* background-color: red; */
  /* margin-bottom: 80px; */
}


.registeruserLoginContainer{
  width:100%;
  height: auto;
  
}


.registeruserLoginContainerSubCard1{
 display: none;
}

.registeruserLoginContainerSubCard2{
  width:90%;
  height:auto;
  /* background-color: blue; */

  float: none;
  position:relative;
  top: 20px;
  right: 0px;
  margin: auto;
}


  /* end media query */
}



@media only screen and (min-width: 885px) and (max-width: 1117px)
{

  /* body{display:none;} */







.navBarContainerx{
  width:50px;
  height: 50px;
  line-height: 60px;
  float: right;
  position: relative;
  top:-65px;
  right:25px;
  display: block;


}


  

/* 
  .navMobile{
    width: 50px;
    height: 50px;
    background-color: red ;
    float: right;
  } */


  /* nav section mobile */
  .logoSection{
    width: 50%;
    height: auto;
    /* background-color: red; */
    margin-top: 0px;
  
    float: none;
    margin-left: 5px;
   
  }
  
  .navItems{
    display: none;
    margin-top: 0px;
    float: none;
    margin-right:0px;
  }


  .logoSection h2{
    line-height: 65px;  
  }




  /* show case section */
.showCaseContainer_Sub_1_Container{
  width:100%;
  height: 450px;
  /* background-color: red; */
  margin-left:0px;
  padding-top: 80px;

}

.showCaseContainer_Sub_1_Container h3{
  font-size:30px;
  padding: 10px;
  text-align: center;
}


.showCaseContainer_Sub_1_Container p{
  padding: 10px;
  font-size:16px;
  /* text-wrap: wrap; */
  text-align: center;
}


.showCaseButton_1{
  width: 90%;
  float: none;
  margin: auto;
  margin-bottom: 18px;

}


.showCaseButton_2{
  width: 90%;
  float: none;
  margin: auto;

}


.showCaseParaBelow{
   text-align: center;
   font-weight: bold;
}




/* about mobile */
.aboutSection{
  width: 100%;
  height:1250px;
  /* background-color: red; */
}


.aboutSection_sub2_1{
  width:100%;
  height:400px;
  /* background-color: pink; */
  float: none;
  margin-left:0px;
}

.aboutSection_sub2_2{
  width:100%;
  height:auto;
  float: none;
  margin-right:0px;
  padding: 15px;
  text-align: center;
}


.companyCert{
  width: 80%;
  margin: auto;
}



.whyChooseUsSection{
  width: 100%;
  height: 1880px;
  /* background-color: red; */
  background-color: #1E2026;
 
}

.whyChooseUsSectionContainer{
  width:100%;
  height: 1300px;
  /* background-color: pink; */
}


.whyChooseUsSectionContainerHeading{
  width: 100%;
  height: 140px;
  margin: auto;
 
}


.whyChooseUsSectionContainerDetailsCard{
  width:95%;
  height: 250px;
  background-color: #181A20;
  float: none;
  margin-right: 0px;
  margin-bottom: 25px;
  position: relative;
  left: 10px;
  
}


.sectionFourContainer{
  width:100%;
  height: 1000px;
}



.sectionFourContainerSubCard1{
  width:95%;
  height: 400px;
  float:none ;
  margin-left:0px;
  margin-bottom: 40px;
  
}

.sectionFourContainerSubCard2{
  width:95%;
  height: 400px;
  /* background-color: blue; */
  float:none ;
  margin-right:0px;
}

.sectionFourContainerSubCard2Details h3{
  padding: 15px;
  text-align: center;
}


.stepsContainer h5{
  /* padding: 15px; */
  text-align: center;
}

.stepsContainer p{
  /* padding: 15px; */
  text-align: center;
}


.faqContainer{
  width:100%;
  height: auto;
  /* background-color: red; */
}


.faqContainerSub{
  width: 90%;
  height: auto;
  margin:auto;
}


.referralContainer{
  width:100%;
  height:250px;
  /* background-color: red; */
}


.referralContainerSub{
  width:90%;
  height: 240px;
  background-color: #1E2026;
  margin:auto;
  margin-top:30px;
  padding: 20px;

}


.cryptoDebitCardContainer{
  width: 100%;
  height: 650px;
  /* background-color: red; */
}


.cryptoDebitCardContainerSubCard1{
  width:80%;
  height: 300px;
  float: none;
  margin-left:20px;
  margin-bottom: 30px;
}

.cryptoDebitCardContainerSubCard2{
  width:90%;
  height: auto;
  /* background-color: red; */
  padding: 10px;
  text-align: center;
  margin-top: 0px;
  float: none;
  margin-right:0px;
  margin: auto;
}


.testimonailContainer{
  width:100%;
  height: auto;
  background-color: #1E2026;
}


.testimonailContainerSub p{
  padding: 10px;
}


.testimonailCard{
  width: 95%;
  height:200px;
  
  float: none;
  margin-right: 0px;
  margin-bottom: 15px;
  position: relative;
  left:10px;
  top:20px;
}



.footerContainer{
  width:100%;
  height: auto;
}


.footerContainerSubCard{
  width: 90%;
  height: 190px;
  /* background-color: red; */

  float: none;
  margin-right:30px;
  position: relative;
  left:5px;
  top:15px;
}

.footerContainerSubCard h4{
  padding-left: 5px;
}

.footerContainerSubCard ul li a{
line-height: 30px;
}



/* about mobile */

.aboutSection1{
  width:100%;
  height: 600px;
  /* background:red;   */
}


.aboutSection1SubCard{
  width:95%;
  height:150px;
  background-color: #1E2026;
 
  float: none;
  margin-right:0px;
  margin-bottom: 20px;

  position: relative;
  top:12px;
  left:10px;

  
}




.ourTeamContainer{
  width:100%;
  height: 800px;
  /* background-color: red; */
  margin-top:30px;
}


.ourTeamContainerSub{
  width:100%;
  height: auto;
  /* background-color: blue; */
  margin: auto;
  margin-top:0px;
}

.ourTeamContainerSubCard{
  width: 95%;
  height: 160px;
  background-color: #1E2026;
  float: none;
  margin-right:0px;
  position: relative;
  left:10px;
}




/* plans */

.plansContainerSubCard{
  width:95%;
  height: 380px;
  background-color: #1E2026;

  float: none;
  margin-right:0px;
  margin-bottom:50px;
  /* margin-left:40px; */
  position: relative;
  left:10px;
  top:20px;

}




/* contact */

.contactContainerContent1{
  width:95%;
  height: auto;
  margin: auto;
  margin-top:50px;
  
}



.otherContactDetails{
  width: 100%;
  height: 650px;
  /* background-color: blue; */
}

.otherContactDetailsCard{
  width:95%;
  height: 200px;
  background-color: #1E2026;

  float: none;
  margin-right:0px;
  position: relative;
  left: 10px;
}



/* login mobile */


.userLoginContainer{
  width:100%;
  height: auto;
  /* background-color: red; */

  margin-bottom: 80px;
}


.userLoginContainerSubCard1{
  display: none;
}


.userLoginContainerSubCard2{
  width:90%;
  height:400px;
  /* background-color: blue; */

  float: none;
  position:relative;
  top: 20px;
  right: 0px;
  /* left: 10px; */
  margin: auto;
}


.forgetPwd{
  color:#eaecef;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  padding: 8px;
  cursor: pointer;
  float: none;
}


.noAcct{
  color:#eaecef;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  padding: 8px;
  padding-top: 40px;
  cursor: pointer;
  float: none;
}


/* register mobile */
.registerContainer{
  width:100%;
  height: 900px;
  /* background-color: red; */
  /* margin-bottom: 80px; */
}


.registeruserLoginContainer{
  width:100%;
  height: auto;
  
}


.registeruserLoginContainerSubCard1{
 display: none;
}

.registeruserLoginContainerSubCard2{
  width:90%;
  height:auto;
  /* background-color: blue; */

  float: none;
  position:relative;
  top: 20px;
  right: 0px;
  margin: auto;
}










}